import { APP_ENV, ENV } from './env';

const isLocal = ENV.APP_ENV === APP_ENV.local;
const isProduction = ENV.APP_ENV === APP_ENV.production;

export const config = {
  isLocal,
  isProduction,
  tracker: {
    clickstream: {
      enabled:
        !!ENV.NEXT_PUBLIC_CLICKSTREAM_API_BASE_URL && !!ENV.AUTH_GUID && !!ENV.GUEST_TOKEN_URI,
    },
    gtm: {
      enabled: !!ENV.NEXT_PUBLIC_GTM_ID,
    },
  },
};
